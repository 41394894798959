import React, { useState } from "react";
import BtnCircular from "../../ds/BtnCircular/BtnCircular";
import { useNavigate, useParams } from "react-router-dom";
import SignInFormHeaderSubTxt from "../../components/atoms/SignInFormHeaderSubTxt/SignInFormHeaderSubTxt";
import SignInFormHeaderMainTxt from "../../components/atoms/SignInFormHeaderMainTxt/SignInFormHeaderMainTxt";
import { useSnackbar } from "notistack";
import {
  useSendOTPEmailMutation,
  useSendOTPPhoneMutation,
} from "../../hooks/auth";
import InputGz from "../../ds/InputGz/InputGz";
import countries from "./countries";
import BtnStandardIconText from "../../ds/BtnStandardIconText/BtnStandardIconText";
import Autocomplete from "../../ds/Autocomplete/Autocomplete";
import { Returntext } from "../Landings/common/returnText";
import { useContent, useLanguages } from "../../hooks/content";
import { ReactComponent as BackIcon } from "../../icons/back-arrow.svg";

const StepOne = ({
  setScreenNumber,
  screenNumber,
  email,
  phone,
  setPhone,
  countryData,
  continueClick,
  setEmail,
  setCountryData,
}) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [cSelectActive, setCSelectActive] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const sendOTPEmailMutation = useSendOTPEmailMutation();
  const sendOTPPHoneMutation = useSendOTPPhoneMutation();

  const { type } = useParams();

  const handleSendEmailOTP = async () => {
    if (!email) {
      setEmailError(true);
      enqueueSnackbar("Email can't be empty!", {
        variant: "error",
      });
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      enqueueSnackbar("Invalid email format!", {
        variant: "error",
      });
      return;
    }
    setEmailError(false);

    try {
      const response = await sendOTPEmailMutation.mutateAsync(email);
      if (response.data.accountExists) {
        setEmailError(false);
        continueClick();
      } else {
        enqueueSnackbar("Account with this email doesn't exists", {
          variant: "error",
        });
        setEmailError(true);
      }
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  };

  const handleSendPhoneOTP = async () => {
    if (!phone || !countryData) {
      setPhoneError(true);
      enqueueSnackbar("Phone Can't be empty!", {
        variant: "error",
      });
      return;
    }
    setPhoneError(false);
    try {
      const response = await sendOTPPHoneMutation.mutateAsync(phone);
      console.log(response);
      if (!response.data.accountExists) {
        setPhoneError(true);
        enqueueSnackbar("Account with this phone doesn't exists", {
          variant: "error",
        });
      } else {
        setPhoneError(false);
        continueClick();
      }
    } catch (err) {
      enqueueSnackbar("!", {
        variant: "error",
      });
    }
  };

  const handlePhoneInputChange = (email) => {
    const input = email.replace(/\D/g, ""); // Remove non-numeric characters
    setPhone(input);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="reset-wrap">
      <div className="top">
        <BtnCircular
          size={"sm"}
          mainColor={"grayBorder"}
          svg={<BackIcon />}
          disabled={false}
          mode={""}
          onClick={() => {
            if (screenNumber === 0) {
              navigate("/sign-in");
            } else {
              setScreenNumber(screenNumber - 1);
            }
          }}
        />
      </div>
      <div className="middle">
        <div className="tt">
          <SignInFormHeaderMainTxt
            txt={Returntext(
              contentManagementData,
              "passwordRecoveryTitle",
              lang
            )}
          />
          <SignInFormHeaderSubTxt
            txt={Returntext(
              contentManagementData,
              "passwordRecoveryText",
              lang
            )}
          />
        </div>
        <div className="inputs-wrap">
          {type === "email" && (
            <>
              <InputGz
                type={"text"}
                value={email}
                setter={setEmail}
                label={Returntext(contentManagementData, "email", lang)}
                nid={"email125152"}
                disabled={false}
                errorM={emailError}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendEmailOTP();
                  }
                }}
              />
            </>
          )}
          {type === "phone" && (
            <div className="p-inputs-wrap">
              <Autocomplete
                type={"text"}
                value={countryData}
                setter={setCountryData}
                label={Returntext(contentManagementData, "CountryRegion", lang)}
                nid={"Country/Region"}
                disabled={false}
                errorM={false}
                style={
                  !cSelectActive
                    ? {
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                      }
                    : {}
                }
                sActive={cSelectActive}
                setSActive={setCSelectActive}
                data={countries}
              />

              {!cSelectActive && (
                <InputGz
                  type={"text"}
                  value={phone}
                  setter={handlePhoneInputChange}
                  label={Returntext(contentManagementData, "phone", lang)}
                  nid={"Phone number"}
                  disabled={false}
                  errorM={phoneError}
                  style={{
                    borderTopRightRadius: "0",
                    borderTopLeftRadius: "0",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSendPhoneOTP();
                    }
                  }}
                />
              )}
            </div>
          )}
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "continue", lang)}
            disabled={false}
            size={"standard"}
            icon={true}
            iconPosition={"right"}
            mainColor={"green"}
            onClick={() => {
              type === "email" ? handleSendEmailOTP() : handleSendPhoneOTP();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
