import React, { useEffect } from "react";
import "./pInfoDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import StepHeader from "../../molecules/StepHeader/StepHeader";
import ListItem from "../../../ds/ListItem/ListItem";
import CopyToClipBoard from "../../atoms/CopyToClipBoard/CopyToClipBoard";
import UploadBox from "../../../ds/UploadBox/UploadBox";
import PInfoFooter from "../../molecules/PInfoFooter/PInfoFooter";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useFileUpload, useOrders } from "../../../hooks/orders";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const PInfoDrawer = ({
  cancel,
  setTipsDrawerActive,
  setStep,
  activeOrder,
  setPInfoDrawer,
  files,
  setFiles,
  setActiveOrder,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const { handleFileUpload } = useFileUpload();
  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, refetchOrders } = useOrders(
    user.id,
    "RECEIVED_IN_TBILISI_WAREHOUSE"
  );

  useEffect(() => {
    const handleFiles = async () => {
      try {
        const dataFiles = {
          files: files,
          orderId: activeOrder.id,
          fileType: "RECEIPT",
        };
        await handleFileUpload(dataFiles);
        await refetchOrders();
        setFiles([]);
      } catch (err) {
        console.log(err);
        refetchOrders();
      } finally {
        const updatedOrder = orders.find(
          (order) => order.id === activeOrder.id
        );
        if (updatedOrder) {
          setActiveOrder(updatedOrder);
        }
      }
    };
    if (files.length > 0) {
      handleFiles();
    }
  }, [files]);

  return (
    <div className="p-info-drawer">
      <div className="shadow" onClick={cancel}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(contentManagementData, "customDrawerT", lang)}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancel}
          />
        </div>

        <div className="body">
          <StepHeader
            step={1}
            main={Returntext(
              contentManagementData,
              "customDrawerStep1_2MH",
              lang
            )}
            onClick={() => setTipsDrawerActive(true)}
          />

          <div className="body-content">
            <div className="content-info-box">
              <div className="main">
                {Returntext(
                  contentManagementData,
                  "customDrawerStep1_2sH",
                  lang
                )}
              </div>
              <div className="sub">
                {Returntext(
                  contentManagementData,
                  "customDrawerStep1_2B",
                  lang
                )}
              </div>
            </div>

            <div className="pdb">
              <div className="pdbt">
                <div className="lt">
                  {Returntext(
                    contentManagementData,
                    "customDrawerStep1_2D",
                    lang
                  )}
                </div>
              </div>

              <div className="pdbi">
                <ListItem
                  hasBorder={false}
                  hasPadding={true}
                  hasIcon={false}
                  headline={`${Returntext(
                    contentManagementData,
                    "customDrawerStep1_2B2H",
                    lang
                  )} ${
                    activeOrder?.custom_fee?.custom_fee_amount?.split(".")[0]
                  }₾ ${Returntext(
                    contentManagementData,
                    "customDrawerStep1_2B2H2",
                    lang
                  )}`}
                  description={`707017021`}
                  buttons={<CopyToClipBoard textToCopy={`707017021`} />}
                />
              </div>
            </div>

            <div className="upload-wrap">
              <div className="txts">
                <div className="main">
                  {Returntext(
                    contentManagementData,
                    "customDrawerStep1_2RH",
                    lang
                  )}
                </div>
                <div className="sub">
                  {Returntext(
                    contentManagementData,
                    "customDrawerStep1_2RB",
                    lang
                  )}
                </div>
              </div>
              <UploadBox
                setFiles={setFiles}
                files={files}
                activeOrder={activeOrder}
                type={"RECEIPT"}
              />
            </div>
          </div>
        </div>

        <div className="footer">
          <PInfoFooter
            cancel={cancel}
            setPInfoDrawer={setPInfoDrawer}
            activeOrder={activeOrder}
            setStep={setStep}
          />
        </div>
      </div>
    </div>
  );
};

export default PInfoDrawer;
